const Shimmer = ()  => {
    return (
        <div class="grid grid-cols-3 gap-4">
        <div class="w-full h-64 shimmer rounded-lg"></div>
        <div class="w-full h-64 shimmer rounded-lg"></div>
        <div class="w-full h-64 shimmer rounded-lg"></div>
        <div class="w-full h-64 shimmer rounded-lg"></div>
        <div class="w-full h-64 shimmer rounded-lg"></div>
        <div class="w-full h-64 shimmer rounded-lg"></div>
        <div class="w-full h-64 shimmer rounded-lg"></div>
        <div class="w-full h-64 shimmer rounded-lg"></div>
        <div class="w-full h-64 shimmer rounded-lg"></div>
    </div>

    );
}

export default Shimmer;