const CATEGORIES = [
    "ALL",
    "Fashion and Apparel",
    "Electronics and Gadgets",
    "Home and Kitchen Appliances", 
    "Beauty and Personal Care",
    "Health and Wellness",
    "Books and Literature",
    "Automotive Parts and Accessories",
    "Sports and Outdoor Equipment",
    "Toys and Games",
    "Pet Supplies",
    "Food and Groceries",
    "Jewelry and Accessories",
    "Arts and Crafts",
    "Office Supplies",
    "Baby Products and Maternity",
    "Furniture and Home Decor",
    "Music and Instruments",
    "Travel and Luggage",
    "Stationery and Gifts",
    "Handmade and Artisan Goods",
];

export default CATEGORIES;