import React from "react";
import ReactDOM from "react-dom/client";
import Header from "./src/components/Header";
import Footer from "./src/components/Footer";
import Body  from "./src/components/Body";

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {
    return (
        <>
            <Header/>
            <Body/>
            <Footer/>
        </>
    );
}

root.render(<App/>);