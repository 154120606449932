import React , { useEffect, useState } from "react";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import CATEGORIES from "../utils/Categories";
import Shimmer from "../Shimmer";


const Body = () => {

    const [images,setImages] = useState([]);
    const [filteredImages,setFilteredImages] = useState([]);

    useEffect(()=>{
        fetchImages();
    },[])

    const fetchImages = async () => {
        const data = await fetch('https://bhargav-kaklotara-2.myshopify.com/products/no-image-image.json');
        const json = await data.json();
        const imageSrcs = json.product.images;
        setImages(imageSrcs);
        setFilteredImages(imageSrcs);
    }

    const filterImages = (seleced_cat) => {

        console.log(seleced_cat + " SELECTED FUCK....");

        if(seleced_cat == "all"){
            setFilteredImages(images);
        }else{
            const a = images.filter( image => (
                image.alt == seleced_cat
            ));
            setFilteredImages(a);
        }
    }

    const categoryChange = (e) => {
        filterImages(e.target.value);
    }

    return (
        images.length == 0 
        ?   <Shimmer/>
        :  
        <>
            <div className="ml-10 max-w-96">
                <label for="countries" class="block mb-2 text-sm font-medium text-gray-9">
                    Select E-Commerce Industry
                </label>
                <select onChange={ categoryChange } className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500">                   
                    {CATEGORIES.map( cat => (
                        <option value={ cat.toLowerCase().replace(/\s+/g, '-') }>
                            {cat}
                        </option>
                    ))}
                </select>
            </div>

            <div className="App">
                <div className="body-wrapper">
                    
                    { filteredImages.length > 0 ?

                        <LightGallery
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                        >
                        {filteredImages.map(image => (  
                            <a href={image.src} className="">
                                <img src={image.src} 
                                    className="object-cover object-center w-full max-w-60 rounded-lg shadow-lg m-3"
                                    alt = {image.alt}
                                />
                            </a> 
                        ))}
                        </LightGallery>

                        : 
                        <div class="flex items-center justify-center">
                            <p class="text-gray-500 text-lg">
                                No Images Under this selection.
                            </p>
                        </div>

                    }
                </div>
            </div>
        </>
    );
}

export default Body;