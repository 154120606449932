import logo from './../../public/images/nii.png'

const Header = () => {
    return (
        <div className='flex justify-center items-center max-w-48 mx-auto'>
            <img src={logo} alt="Logo" />
        </div>
    );
}

export default Header