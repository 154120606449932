const Footer = () => {

    return (
        <footer className="fixed bottom-0 left-0 z-20 w-full p-4 lg:text-center bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
            <span className="block text-sm text-gray-500 dark:text-gray-500">© 2023  &nbsp;
                <a href="#" className="hover:underline">
                    Dummy Produdct Images By Manifester Technologies
                </a>
            </span>
        </footer>
    );

}

export default Footer;